import request from '@/utils/request';

export function creditRatingAdd(params) {
  return request({
    url: '/credit_rating/level',
    methods: 'get',
    params
  });
}

export function getCreditRatingRow(params) {
  return request({
    url: '/credit_rating/row',
    methods: 'get',
    params
  });
}

export function getCreditRatingList(params) {
  return request({
    url: '/Credit_rating/data',
    methods: 'get',
    params
  });
}
