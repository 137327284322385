<template>
  <page-header :title="title" :breadcrumbs="pathMatch">
    <ele-form
      class="form-wrap"
      v-model="RowInfo"
      :form-desc="formDesc"
      ref="form"
      labelPosition="top"
      :isShowSubmitBtn="true"
      submitBtnText="保存"
      :isShowBackBtn="false"
      :span="24"
      :request-fn="handleSubmit"
      :isShowErrorNotify="false"
    >
      <el-form-item>
        <h4>请填写油站等级参数</h4>
      </el-form-item>
    </ele-form>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import { getCreditRatingRow, creditRatingAdd } from '@/api/honesty_system';

export default {
  components: {
    PageHeader
  },
  data() {
    const _rules = [
      { required: true, message: ' ' },
      { pattern: /^[1-9][0-9]{0,8}$/, message: '请输入正整数' }
    ];
    return {
      formData: {},

      formDesc: {
        A: {
          type: 'input',
          layout: 24,
          label: 'A级报警数量',
          rules: _rules
        },
        B: {
          type: 'input',
          layout: 24,
          label: 'B级报警数量',
          rules: _rules
        },
        C: {
          type: 'input',
          layout: 24,
          label: 'C级报警数量',
          rules: _rules
          // },
          // D: {
          //   type: 'input',
          //   layout: 24,
          //   label: 'D级报警数量',
          //   rules: [{ pattern: /^[1-9][0-9]{0,8}$/, message: '请输入正整数' }]
        }
      },
      RowInfo: {}
    };
  },
  computed: {
    title() {
      const currentName = this.$router.history.current.name;
      return this.$locales[currentName];
    }
  },
  created() {
    this.pathMatch = this.$router.history.current.matched;
    this.getInfo();
  },
  methods: {
    getInfo() {
      getCreditRatingRow()
        .then(res => {
          const { code, data, msg } = res;
          console.log(data);
          if (code === 0) {
            this.RowInfo = {
              ...data
            };
          } else {
            this.$message({
              message: msg || '数据请求失败!',
              type: 'error'
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    async handleSubmit(fields) {
      let params = {
        ...fields
      };
      console.log(params);
      let result;
      result = await creditRatingAdd(params);
      const { code, msg } = result;
      if (code === 0) {
        this.$message({
          message: '保存成功',
          type: 'success'
        });
      } else {
        this.$message({
          message: msg,
          type: 'error'
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.form-wrap {
  width: 400px;
  margin: auto;
}

.form-item-tips {
  color: #f8150d;
  font-size: 12px;
}
</style>
